(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbCta.service:CTAWidget
   *
   * @description
   *
   */
  angular
    .module('wbCta')
    .service('CTAWidget', CTAWidget);

  function CTAWidget(EdenredCTAClient, LoggedUser, $q, _, $translate, $state, appConfig) {
    var self = this;

    self.open = function () {
      getUserOptions()
        .then(function (userOptions) {
          EdenredCTAClient.show({
            environment: appConfig.debug ? 'staging' : 'production',
            user: userOptions
          });
        });
    };

    /**
     * Returns a promise, which resolves to an object of user options.
     *
     * @returns {Promise}
     */
    function getUserOptions() {
      return $q(function (resolve) {
        var user = LoggedUser.getUser()
          , company = LoggedUser.getCompany()
          , userOptions;

        userOptions = {
          visitorType: 'client',
          isLoggedIn: !_.isNull(user),
          language: $translate.use()
        };

        if (!_.isNull(user) && !_.isNull(company)) {
          userOptions.name = user.lastName + ' ' + user.firstName;
          userOptions.email = user.email;
          userOptions.phone = user.phone;
          userOptions.vatNumber = company.vatNumber;
        }

        return resolve(userOptions);
      });
    }

    function goToDataChangeState() {
      $state.go('account');
    }

    function bindEvents() {
      EdenredCTAClient.eventBus.on('dataChangeButtonClick', goToDataChangeState);
    }

    bindEvents();
  }
}());
